import { useStaticQuery, graphql } from 'gatsby';

export const useImages = () =>
	useStaticQuery(graphql`
		query {
			heading: file(relativePath: { eq: "job_heading.png" }) {
				childImageSharp {
					fluid(quality: 80, maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			newspaper: file(relativePath: { eq: "newspaper.png" }) {
				childImageSharp {
					fluid(
						quality: 80
						maxWidth: 1200
						traceSVG: { color: "#ece9e3" }
					) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				}
			}
			leaves: file(relativePath: { eq: "leaves.png" }) {
				childImageSharp {
					fluid(quality: 80, maxWidth: 200) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			filler_1: file(
				relativePath: { eq: "newspaper_images/NON_JOB_RADIO.png" }
			) {
				childImageSharp {
					fluid(quality: 80, maxWidth: 333) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			filler_2: file(
				relativePath: {
					eq: "newspaper_images/NON_JOB_LORD_OF_THE_WINGS.png"
				}
			) {
				childImageSharp {
					fluid(quality: 80, maxWidth: 333) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			full_wide: file(
				relativePath: {
					eq: "newspaper_images/NON_JOB_GRAND_OPENING.png"
				}
			) {
				childImageSharp {
					fluid(quality: 80, maxWidth: 1000) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			half_page: file(
				relativePath: { eq: "newspaper_images/NON_JOB_MONEY.png" }
			) {
				childImageSharp {
					fluid(quality: 80, maxWidth: 1000) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
		}
	`);
