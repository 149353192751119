import React from 'react';
import Image from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { motion } from 'framer-motion';
import { useMedia } from 'react-use';

import { JobTile } from 'src/components/jobs';
import { useImages } from './newspaper.images';

import style from './newspaper.module.scss';

export default function Newspaper({ jobs }) {
	const isSmall = useMedia('(max-width: 480px)');
	const isMed = useMedia('(max-width: 768px)');

	const {
		filler_1,
		filler_2,
		newspaper,
		heading,
		full_wide,
		half_page,
		leaves
	} = useImages();

	let overflowCount = 0;

	if (isSmall) {
		overflowCount = jobs.length === 1 ? 1 : overflowCount;
	} else if (isMed) {
		overflowCount = 2 - (jobs.length % 2);
		overflowCount = overflowCount === 2 ? 0 : overflowCount;
	} else {
		overflowCount = 3 - (jobs.length % 3);
		overflowCount = overflowCount === 3 ? 0 : overflowCount;
	}

	const overflowImages = [
		filler_1.childImageSharp.fluid,
		filler_2.childImageSharp.fluid
	];

	// const paperVariants = {
	// 	initial: {
	// 		x: -500,
	// 		y: '-300%',
	// 		rotateZ: -60,
	// 		rotateX: -20,
	// 		perspective: 100
	// 	},
	// 	rest: {
	// 		x: -25,
	// 		y: '0%',
	// 		rotateZ: 3,
	// 		rotateX: 0,
	// 		transition: {
	// 			delay: 1,
	// 			type: 'spring',
	// 			damping: 3,
	// 			mass: 0.1,
	// 			stiffness: 5
	// 		}
	// 	}
	// };

	console.log(jobs);

	return (
		<motion.div
			className={style.container}
			variants={null}
			initial="initial"
			animate="rest"
		>
			<BackgroundImage
				fluid={newspaper.childImageSharp.fluid}
				className={style.background}
			>
				<div className={style.newspaper}>
					<Image
						fluid={heading.childImageSharp.fluid}
						className={style.heading}
					></Image>
					<div className={style.tiles}>
						{jobs.map(
							edge =>
								edge.node.frontmatter.position !== 'filler' && (
									<JobTile
										key={edge.node.id}
										post={edge.node}
									/>
								)
						)}
						{overflowImages
							.slice(0, overflowCount)
							.map((img, index) => (
								<Image
									className={style.filler}
									fluid={img}
									key={index}
									alt=""
								></Image>
							))}
					</div>
					{jobs.length === 3 && (
						<Image
							className={style.smallHalf}
							fluid={full_wide.childImageSharp.fluid}
							alt=""
						></Image>
					)}
					<Image
						className={style.half}
						fluid={half_page.childImageSharp.fluid}
						alt=""
					></Image>
				</div>
			</BackgroundImage>
			<div className={style.leaves}>
				<Image fluid={leaves.childImageSharp.fluid}></Image>
			</div>
		</motion.div>
	);
}
