import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import BackgroundImage from 'gatsby-background-image';

import { Nav, Footer } from 'src/components/shared';

import 'src/styles/reset.css';
import 'src/styles/global.scss';
import style from './layout.module.scss';

export default function Layout({
	children,
	variant = 'normal',
	backgroundProps,
	withLogo
}) {
	const BgComponent = backgroundProps ? BackgroundImage : 'div';

	return (
		<div className={style.wrapper}>
			<BgComponent {...backgroundProps} className={style.content}>
				<Nav withLogo={withLogo}></Nav>
				<main
					className={clsx(style.main, style[variant], {
						[style['withLogo']]: withLogo
					})}
				>
					{children}
				</main>
			</BgComponent>
			<Footer></Footer>
		</div>
	);
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	variants: PropTypes.oneOf(['normal', 'wide', 'full'])
};
