import React from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';

import style from './button.module.scss';

export default function Button({
	className,
	size = 'big',
	theme = 'yellow',
	...props
}) {
	const ButtonComponent = props.to ? Link : 'button';
	return (
		<ButtonComponent
			{...props}
			className={clsx(
				className,
				style.button,
				style['size_' + size],
				style['theme_' + theme]
			)}
		></ButtonComponent>
	);
}
