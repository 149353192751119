import React from 'react';

import style from './jobintro.module.scss';

export default function JobPageIntro() {
	return (
		<div className={style.intro}>
			<h1 className={style.heading}>Job Opportunities</h1>
			<p className={style.para}>
				Funko Animation Studios are always on the look out for talented
				people to join our team...
			</p>
		</div>
	);
}
