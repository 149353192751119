import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';

import style from './jobtile.module.scss';

function safeString(str) {
	return str
		.replace(/([a-z])([A-Z])/g, '$1-$2')
		.replace(/\s+/g, '-')
		.toLowerCase();
}

export default function JobTile({ post }) {
	return (
		<Link
			className={style.link}
			to={`jobs/${post.frontmatter.date}_${safeString(
				post.frontmatter.position
			)}`}
		>
			<Image
				fluid={post.frontmatter.thumbnail.childImageSharp.fluid}
			></Image>
		</Link>
	);
}
