import React, { useState } from 'react';
import { Location } from '@reach/router';
import { motion, AnimatePresence } from 'framer-motion';

import { Button, Portal } from 'src/components/shared';
import style from './jobsbrief.module.scss';
import clsx from 'clsx';

const getLocation = location => {
	const locations = {
		in_house: 'In-House',
		remote: 'Remote',
		flexible: 'Flexible'
	};
	return locations[location];
};

const getContract = contract => {
	const contracts = {
		all: 'All',
		permanent: 'Permanent',
		freelance: 'Freelance',
		intern: 'Internship / Placement',
		temp: 'Temporary',
		maternity: 'Maternity Cover'
	};
	return contracts[contract];
};

export default function JobBrief({ frontmatter, html, excerpt }) {
	const [openMore, setOpenMore] = useState(false);

	return (
		<div className={style.brief}>
			<h1 className={style.title}>{frontmatter.position}</h1>

			{frontmatter.position !== 'Speculative Application' && (
				<ul className={style.infobox}>
					<li className={style.infoItem}>
						Closing date: <span>{frontmatter.endDate}</span>
					</li>

					<li className={style.infoItem}>
						Salary: <span>{frontmatter.salary}</span>
					</li>
					<li className={style.infoItem}>
						Location:{' '}
						<span>{getLocation(frontmatter.location)}</span>
					</li>
					<li className={style.infoItem}>
						Contract Type:{' '}
						<span>{getContract(frontmatter.contract)}</span>
					</li>
				</ul>
			)}

			<div
				className={style.content}
				dangerouslySetInnerHTML={{ __html: excerpt }}
			/>
			<Button
				size="small"
				theme="blue"
				className={style.readMoreLink}
				onClick={() => setOpenMore(true)}
			>
				Read more
			</Button>

			<div className={style.apply}>
				<Location>
					{({ location }) => (
						<Button to={`${location.pathname}/apply`}>
							Apply Now!
						</Button>
					)}
				</Location>
			</div>

			<ReadMore
				html={html}
				openMore={openMore}
				setOpenMore={setOpenMore}
			></ReadMore>
		</div>
	);
}

function ReadMore({ html, openMore, setOpenMore }) {
	const onClose = () => {
		setOpenMore(false);
	};

	return (
		<Portal>
			<AnimatePresence>
				{openMore && (
					<motion.div
						className={style.blackout}
						initial="initial"
						animate="enter"
						exit="exit"
						variants={{
							initial: {
								opacity: 0
							},
							enter: { opacity: 1 },
							exit: {
								opacity: 0
							}
						}}
					>
						<motion.div
							className={style.modal}
							exit="exit"
							variants={{
								initial: {
									scale: 1.1,
									opacity: 0
								},
								enter: { opacity: 1, scale: 1, delay: 0.5 },
								exit: { opacity: 0 }
							}}
						>
							<button
								className={style.modalClose}
								onClick={onClose}
							>
								Close
							</button>

							<div
								className={clsx(
									style.modalContent,
									style.content
								)}
								dangerouslySetInnerHTML={{ __html: html }}
							></div>
						</motion.div>
					</motion.div>
				)}
			</AnimatePresence>
		</Portal>
	);
}
