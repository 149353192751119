import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import style from './nav.module.scss';
import clsx from 'clsx';

const menuItems = [
	{
		name: 'Home',
		url: '/'
	},
	{
		name: 'Jobs',
		url: '/jobs'
	}
];

export default function Nav({ withLogo }) {
	const data = useStaticQuery(graphql`
		query {
			logo: file(relativePath: { eq: "logo.png" }) {
				childImageSharp {
					fluid(
						quality: 80
						maxWidth: 350
						traceSVG: { color: "#4989ff" }
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
		}
	`);

	return (
		<div className={clsx(style.nav, { [style.withLogo]: withLogo })}>
			<div className={style.container}>
				{withLogo && (
					<Link to="/" className={style.logoContain}>
						<Image
							fluid={data.logo.childImageSharp.fluid}
							className={style.logo}
						></Image>
					</Link>
				)}
				<ul className={style.navList}>
					{menuItems.map(item => (
						<li key={item.url} className={style.navItem}>
							<Link to={item.url}>{item.name}</Link>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}
