import * as Yup from 'yup';
// import differenceInYears from 'date-fns/difference_in_years';

const YupFile = Yup.mixed()
	.required('A file is required')
	.test(
		'fileSize',
		'File must be less than 10MB',
		value => value && value.size <= 10 * 1024 * 1024
	);

export default Yup.object().shape({
	_subject: Yup.string().required(),
	firstname: Yup.string().required('Please enter your first name'),
	lastname: Yup.string().required('Please enter your last name'),
	date_of_birth: Yup.date().required('Please enter your Date of Birth'),
	_replyto: Yup.string()
		.email('This must be an email address')
		.required('Please enter an email'),
	phone: Yup.string().required('Please enter a valid phone number'),
	address_1: Yup.string().required('Required'),
	address_2: Yup.string(),
	address_city: Yup.string().required('Required'),
	address_county: Yup.string(),
	address_postcode: Yup.string().required('Required'),
	address_country: Yup.string().required('Required'),
	cv: YupFile,
	cover_leter: YupFile,
	showreel: Yup.string().url('Must be a valid URL'),
	showreel_password: Yup.string(),
	website: Yup.string().url('Must be a valid URL'),
	'has EU passport': Yup.boolean(),
	'work in house': Yup.boolean(),
	'must relocate': Yup.boolean(),
	'current location': Yup.string().required(
		'Please select where you currently live'
	),
	start_date: Yup.date().required('Please enter a valid date')
	// ref_name: Yup.string().required('Required'),
	// ref_position: Yup.string().required('Required'),
	// ref_company: Yup.string().required('Required'),
	// ref_phone: Yup.string().required('Required'),
	// ref_email: Yup.string()
	// 	.email('This must be an email address')
	// 	.required('Required')
});
