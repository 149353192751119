import React from 'react';
import Image from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { useStaticQuery, graphql } from 'gatsby';

import style from './mainimage.module.scss';

export default function JobMainImage(props) {
	const images = useStaticQuery(graphql`
		query {
			bg: file(relativePath: { eq: "ad_bg.png" }) {
				childImageSharp {
					fluid(quality: 80, maxWidth: 1200) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				}
			}
		}
	`);

	return (
		<div className={style.container}>
			<BackgroundImage
				className={style.background}
				fluid={images.bg.childImageSharp.fluid}
			>
				<Image {...props}></Image>
			</BackgroundImage>
		</div>
	);
}
