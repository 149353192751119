import React from 'react';
import { Field } from 'formik';
import { SimpleFileUpload, Switch, TextField } from 'formik-material-ui';
import { FormControlLabel, FormControl } from '@material-ui/core';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';

export function TextInput({ label, name, type = 'text', ...props }) {
	return (
		<Field
			name={name}
			label={label}
			type={type}
			component={TextField}
			margin="normal"
			variant="filled"
			{...props}
		/>
	);
}

export function FileUpload({ label, name, ...props }) {
	return (
		<Field
			name={name}
			label={label}
			component={SimpleFileUpload}
			margin="normal"
			variant="filled"
			{...props}
		/>
	);
}

export function SelectField({ children, ...props }) {
	return (
		<TextField select {...props} margin="normal" variant="filled">
			{children}
		</TextField>
	);
}

export function Select({ name, label, children, ...props }) {
	return (
		<FormControl>
			<Field label={label} name={name} component={SelectField} {...props}>
				{children}
			</Field>
		</FormControl>
	);
}

export function Toggle({ label, name, ...props }) {
	return (
		<FormControlLabel
			control={<Field component={Switch} name={name} {...props}></Field>}
			label={label}
			style={{ color: 'white' }}
			labelPlacement="end"
		/>
	);
}

const DatePickerField = ({ field, form, ...other }) => {
	const currentError = form.errors[field.name];

	return (
		<MuiDatePicker
			clearable
			name={field.name}
			value={field.value}
			format="dd/MM/yyyy"
			helperText={currentError}
			error={Boolean(currentError)}
			inputVariant="filled"
			onError={error => {
				// handle as a side effect
				if (error !== currentError) {
					form.setFieldError(field.name, error);
				}
			}}
			// if you are using custom validation schema you probably want to pass `true` as third argument
			onChange={date => form.setFieldValue(field.name, date, false)}
			{...other}
		/>
	);
};

export function DatePicker({ label, name, ...props }) {
	return (
		<Field
			name={name}
			label={label}
			component={DatePickerField}
			{...props}
		/>
	);
}
