import React from 'react';
import clsx from 'clsx';

import style from './jobslayout.module.scss';

export function JobsLayout({ children, variant = 'balanced' }) {
	return (
		<section className={clsx(style.layout, style[variant])}>
			{children}
		</section>
	);
}

export function LayoutLeft({ children, ...props }) {
	return (
		<div className={style.left} {...props}>
			{children}
		</div>
	);
}

export function LayoutRight({ children, ...props }) {
	return (
		<div className={style.right} {...props}>
			{children}
		</div>
	);
}

export function LayoutCenter({ children, ...props }) {
	return (
		<div className={style.center} {...props}>
			{children}
		</div>
	);
}
