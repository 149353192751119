import React from 'react';
import clsx from 'clsx';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import style from './footer.module.scss';

export default function Footer() {
	return (
		<footer className={style.footer}>
			<div className={style.list}>
				<div className={style.group}>
					<div className={style.key}>Find us</div>
					<div className={style.detail}>
						<OutboundLink
							href="https://goo.gl/maps/xKhqo5L2tCw"
							target="_blank"
							rel="noopener noreferrer"
						>
							11 SEVEN DIALS, SAWCLOSE, BATH, BA1 1EN, UK
						</OutboundLink>
					</div>
				</div>
				<div className={style.group}>
					<div className={style.key}>Say Hello</div>
					<div className={style.detail}>
						<OutboundLink href="mailto:fasinfo@funko.com">
							fasinfo@funko.com
						</OutboundLink>{' '}
						<span style={{ marginLeft: 10 }}>
							TEL:{' '}
							<OutboundLink href="tel:+441225461122">
								+44 (0) 1225 461122
							</OutboundLink>
						</span>
					</div>
				</div>

				<div className={style.group}>
					<div className={style.key}>Follow</div>
					<div className={clsx(style.detail, style.icons)}>
						<OutboundLink
							href="https://www.facebook.com/FunkoAnimationStudios"
							target="_blank"
							rel="noopener noreferrer"
							alt=""
							className={clsx(style.icon, style.facebook)}
						>
							Facebook
						</OutboundLink>

						<OutboundLink
							href="https://twitter.com/funkoanimation"
							target="_blank"
							rel="noopener noreferrer"
							alt=""
							className={clsx(style.icon, style.twitter)}
						>
							Twtitter
						</OutboundLink>

						<OutboundLink
							href="https://www.instagram.com/funkoanimationstudios/"
							target="_blank"
							rel="noopener noreferrer"
							alt=""
							className={clsx(style.icon, style.instagram)}
						>
							Instagram
						</OutboundLink>

						<OutboundLink
							href="https://www.linkedin.com/company/funko-animation-studios"
							target="_blank"
							rel="noopener noreferrer"
							alt=""
							className={clsx(style.icon, style.linkedin)}
						>
							LinkedIn
						</OutboundLink>
					</div>
				</div>
			</div>
		</footer>
	);
}
