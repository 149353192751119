import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import axios from 'axios';
import objectToFormData from 'object-to-formdata';
import { motion, AnimatePresence } from 'framer-motion';
import Switch, { Case, Default } from 'react-switch-case';
import format from 'date-fns/format';

import {
	TextInput,
	FileUpload,
	DatePicker,
	Toggle,
	Select,
	Button
} from 'src/components/shared';
import validationSchema from './validationSchema';
import countries from './countries.json';

import style from './jobform.module.scss';

const theme = createMuiTheme({
	typography: {
		fontFamily: "'museo-sans', sans-serif"
	},
	palette: {
		type: 'dark',
		primary: {
			main: '#fcc04d'
		},
		secondary: {
			main: '#427ce8'
		}
	}
});

const initialValues = {
	firstname: '',
	lastname: '',
	date_of_birth: new Date('1980-01-01'),
	_replyto: '',
	phone: '',
	address_1: '',
	address_2: '',
	address_city: '',
	address_county: '',
	address_postcode: '',
	address_country: '',
	cv: '',
	cover_leter: '',
	showreel: '',
	showreel_password: '',
	website: '',
	'has EU passport': false,
	'work in house': false,
	'must relocate': false,
	'current location': '',
	start_date: new Date()
	// ref_name: '',
	// ref_position: '',
	// ref_company: '',
	// ref_phone: '',
	// ref_email: ''
};

const variants = {
	appear: {
		opacity: 1,
		height: 'auto'
	},
	exit: {
		opacity: 0,
		height: 0
	}
};

export default function JobForm({ data }) {
	const [mode, setMode] = useState('form');

	const onSubmit = async ({
		address_1,
		address_2,
		address_city,
		address_county,
		address_postcode,
		address_country,
		date_of_birth,
		start_date,
		...form
	}) => {
		const addr = [
			address_1,
			address_2,
			address_city,
			address_county,
			address_postcode,
			address_country
		].join('\r\n');

		const dob = format(date_of_birth, 'dd-MM-yyyy');
		const sd = format(start_date, 'dd-MM-yyyy');

		const newForm = {
			...form,
			address: addr,
			date_of_birth: dob,
			start_date: sd
		};

		setMode('sending');
		const formdata = objectToFormData(newForm);

		const res = await axios.post('https://formspree.io/mbbjvvwm', formdata);
		console.log(res);

		setTimeout(() => {
			if (res.status === 200) {
				setMode('sent');
			} else {
				setMode('failed');
			}
		}, 2000);
	};

	return (
		<ThemeProvider theme={theme}>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<AnimatePresence>
					<Switch condition={mode}>
						<Case value="sending">
							<SendingMessage></SendingMessage>
						</Case>

						<Case value="sent">
							<CompleteMessage></CompleteMessage>
						</Case>
						<Case value="failed">
							<FailedMessage></FailedMessage>
						</Case>

						<Default>
							<ActualForm
								data={data}
								onSubmit={onSubmit}
							></ActualForm>
						</Default>
					</Switch>
				</AnimatePresence>
			</MuiPickersUtilsProvider>
		</ThemeProvider>
	);
}

function SendingMessage() {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 1 }}
			exit={{ opacity: 0 }}
			className={style.complete}
		>
			<h1 className={style.completeTitle}>Uploading…</h1>
		</motion.div>
	);
}

function FailedMessage() {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 1 }}
			exit={{ opacity: 0 }}
			className={style.complete}
		>
			<h1 className={style.completeTitle}>Sorry, something went wrong</h1>

			<Button
				className={style.completeButton}
				onClick={() => {
					window.location.reload();
				}}
			>
				Try again
			</Button>
		</motion.div>
	);
}

function CompleteMessage() {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 1 }}
			className={style.complete}
		>
			<h1 className={style.completeTitle}>Application submitted!</h1>
			<Button to="/jobs" className={style.completeButton}>
				Back to Jobs
			</Button>
		</motion.div>
	);
}

function ActualForm({ data, onSubmit }) {
	return (
		<motion.div
			className={style.wrapper}
			variants={variants}
			animate="appear"
			exit="exit"
			transition={{
				transition: {
					duration: 1.5
				}
			}}
		>
			<h1 className={style.title}>Apply</h1>
			<Formik
				initialValues={{
					_subject: `New applicant for ${data.position}`,
					...initialValues
				}}
				onSubmit={onSubmit}
				validationSchema={validationSchema}
			>
				<Form>
					<Field
						type="hidden"
						name="_subject"
						value={`New applicant for ${data.position}`}
					/>
					<div className={style.block}>
						<h2 className={style.blockHeader}>About You</h2>

						<Group>
							<TextInput
								label="First Name"
								name="firstname"
								required
							></TextInput>

							<TextInput
								label="Last Name"
								name="lastname"
								required
							></TextInput>
						</Group>

						<Group>
							<DatePicker
								label="Date of Birth"
								name="date_of_birth"
								fullWidth
								helperText="Click the year at the top to easily select a year"
								required
							></DatePicker>
						</Group>

						<Group>
							<TextInput
								label="Email"
								name="_replyto"
								type="email"
								fullWidth
								required
							></TextInput>

							<TextInput
								label="Phone"
								name="phone"
								type="tel"
								fullWidth
								required
							></TextInput>
						</Group>

						<Group>
							<TextInput
								label="Address Line 1"
								name="address_1"
								required
							></TextInput>
							<TextInput
								label="Address Line 2"
								name="address_2"
							></TextInput>
							<TextInput
								label="City"
								name="address_city"
								required
							></TextInput>
							<TextInput
								label="County"
								name="address_county"
							></TextInput>
							<TextInput
								label="Postcode"
								name="address_postcode"
								required
							></TextInput>

							<Select
								label="Country"
								name="address_country"
								style={{ minWidth: 280 }}
								required
							>
								<MenuItem value="United Kingdom">
									United Kingdom
								</MenuItem>
								<MenuItem value="">-------</MenuItem>
								{countries.map(value => (
									<MenuItem key={value} value={value}>
										{value}
									</MenuItem>
								))}
							</Select>
						</Group>
					</div>

					<div className={style.block}>
						<h2 className={style.blockHeader}>
							Your CV, showreel, and cover letter
						</h2>

						<Group>
							<FileUpload
								label="Your CV"
								name="cv"
								fullWidth
								required
							></FileUpload>
						</Group>

						<Group>
							<FileUpload
								label="Your covering letter"
								name="cover_leter"
								fullWidth
								required
							></FileUpload>
						</Group>

						{data.showreel && (
							<Group>
								<TextInput
									label="A link to your showreel"
									name="showreel"
								></TextInput>

								<TextInput
									label="Showreel password"
									name="showreel_password"
								></TextInput>
							</Group>
						)}

						<Group>
							<TextInput
								label="Your blog or website (optional)"
								name="website"
								fullWidth
							></TextInput>
						</Group>

						<Group>
							<DatePicker
								label="What is your current availability? (notice period, current contract end, etc)"
								name="start_date"
								disablePast
								fullWidth
								required
							></DatePicker>
						</Group>
					</div>

					<div className={style.block}>
						<h2 className={style.blockHeader}>Right to work</h2>

						<Group>
							<Toggle
								name="has EU passport"
								label="Do you own an EU passport, and therefore possess the right to work in the UK without a the requirement of a visa or sponsorship?"
								value="Yes"
							></Toggle>

							<Toggle
								name="work in house"
								label="Are you able to work full-time, in-house at our studios in Bath, UK?"
								value="Yes"
							></Toggle>

							<Toggle
								name="must relocate"
								label="Should you be offered a position at Funko Animation Studios, will you need to relocate to fulfil the role?"
								value="Yes"
							></Toggle>
						</Group>

						<Group>
							<Select
								label="Where do you currently live?"
								name="current location"
								style={{ minWidth: 280 }}
								required
							>
								{/* <MenuItem value=""></MenuItem> */}
								<MenuItem value="UK">In the UK</MenuItem>
								<MenuItem value="EU">In the EU</MenuItem>
								<MenuItem value="Rest of the World">
									Rest of the World
								</MenuItem>
							</Select>
						</Group>
					</div>

					{/* <div className={style.block}>
						<h2 className={style.blockHeader}>Referee Info</h2>

						<Group>
							<TextInput
								label="Name of Referee"
								name="ref_name"
								fullWidth
								required
							></TextInput>

							<TextInput
								label="Referee Position"
								name="ref_position"
								required
							></TextInput>

							<TextInput
								label="Referee Company"
								name="ref_company"
								required
							></TextInput>

							<TextInput
								label="Referee Phone Number"
								name="ref_phone"
								type="tel"
								fullWidth
								required
							></TextInput>

							<TextInput
								label="Referee Email Address"
								name="ref_email"
								type="email"
								fullWidth
								required
							></TextInput>
						</Group>
					</div> */}

					<div className={style.button}>
						<Field
							render={({ form }) => (
								<Button
									type="submit"
									disabled={form.isSubmitting}
								>
									Send
								</Button>
							)}
						></Field>
					</div>
				</Form>
			</Formik>
		</motion.div>
	);
}

function Group({ children }) {
	return <div className={style.group}>{children}</div>;
}
